import { Redirect } from "@reach/router";
import { Button } from "luxd";
import React, { ReactElement, FC } from "react";
import {
    MdEmail,
    MdReportProblem,
    MdSend,
    MdVerifiedUser,
} from "react-icons/md";
import { Error, User } from "../../types";
import { SocialLinks } from "../SocialLinks";
import { FormStatus } from "./forms";

export type VerifyEmailFormProps = {
    status?: FormStatus;
    error?: Error;
    account: User;
    handleResend: () => void;
};

export const VerifyEmailForm: FC<VerifyEmailFormProps> = ({
    status,
    error,
    handleResend,
    account,
}): ReactElement => {
    return (
        <div className="flex flex-col space-y-8 justify-between mx-4 mt-8 text-offWhite">
            <h1 className="text-2xl font-display text-center">
                Welcome to Streamlux!
            </h1>
            <p>
                Thank you for signing up for Streamlux! Please verify your email
                address to finish setting up your account.
            </p>
            <div className="flex flex-row items-center space-x-8 justify-evenly">
                <div>
                    <MdEmail size={32} />
                </div>
                <p className="text-lg">
                    An email has been sent to your email address{" "}
                    <strong>{account.email}</strong> It might take a few minutes
                    to arrive.
                </p>
            </div>
            <div className="flex flex-row items-center space-x-8">
                <div>
                    <MdVerifiedUser size={32} />
                </div>
                <p className="text-lg">
                    Please click on the link in the email to verify your email
                    address.
                </p>
            </div>
            <div className='flex flex-col space-y-4 items-center'>
                <div className="flex flex-row space-x-4 justify-center pt-4 items-center">
                    <div className="flex flex-row items-center space-x-1">
                        <p>Didn't recieve an email?</p>
                    </div>
                    <Button
                        icon={<MdSend />}
                        loading={status === "loading"}
                        size="small"
                        onClick={handleResend}
                    >
                        Click to resend
                    </Button>
                </div>
            </div>

            <SocialLinks iconClassnames="w-6" />

            {error && <p className="text-lg text-error">{error.message}</p>}
        </div>
    );
};
