import { toast } from 'react-toast';

export type Toast = {
    successText: string;
    errorText: string;
    toaster: ToastApi;
};

export type Toaster = {
    success?: () => void;
    error?: (error: Error) => void;
};

export type ToastApi = {
    success: (text: string) => void;
    error: (text: string, error: Error) => void;
};

export const awToast: ToastApi = {
    success: (text: string): void => {
        toast.success(text);
    },
    error: (text: string, error: Error): void => {
        toast.error(`${text}: ${error.message}`);
    }
};

export function createToaster(options: { successText?: string; errorText?: string; toaster?: ToastApi }): Toaster {
    const { successText, errorText, toaster } = options;
    const api = toaster ?? awToast;
    return {
        success: successText ? () => api.success(successText) : undefined,
        error: errorText ? (error: Error) => api.error(errorText, error) : undefined
    };
}
