import React, { ReactElement, useContext, useState } from "react";
import { AppwriteContext } from "../appwriteContext";
import { navigate } from "@reach/router";
import { FormStatus } from "../components/forms/forms";
import { ApiCall } from "../appwrite/appwrite";
import { Token, Error } from "../types";
import { VerifyEmailForm } from "../components/forms/VerifyEmailForm";
import { AppWriteVerifyUrl } from "../constants";
import { createToaster } from "../utils/toast";
import LoginContainer from "../components/forms/loginFormContainer";
import useAccount from "../hooks/useAccount";

const VerifyPage = (): ReactElement => {
    const [error, setError] = useState<Error | undefined>(undefined);
    const [status, setStatus] = useState<FormStatus>();

    const appwrite = useContext(AppwriteContext);

    const [user, loading, userError] = useAccount();

    if (loading || !user) {
        return <>loading</>;
    }

    if (user.emailVerification) {
        navigate('/');
    }

    const resendVerificationEmail = () => {
        setStatus("loading");
        ApiCall<Token>(
            appwrite.account.createVerification(AppWriteVerifyUrl),
            (_resp: Token) => {
                setStatus("success");
            },
            (err: Error) => {
                setError(err);
                setStatus("error");
            },
            createToaster({
                successText: "Verification email sent",
                errorText: "Failed to send verification email",
            })
        );
    };

    return (
        <>
            {user && (
                <LoginContainer>
                    <VerifyEmailForm
                        account={user}
                        handleResend={resendVerificationEmail}
                        error={error}
                        status={status}
                    />
                </LoginContainer>
            )}
            <p>loaing</p>
        </>
    );
};

export default VerifyPage;
